/**
 * @license
 * Copyright 2018 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { __spreadArray } from "tslib";
import { ListenEvent } from '../interfaces';
import { fromRef } from '../fromRef';
import { map, withLatestFrom, scan, skipWhile } from 'rxjs/operators';
import { stateChanges } from './index';
export function auditTrail(query, events) {
    var auditTrail$ = stateChanges(query, events).pipe(scan(function (current, changes) { return __spreadArray(__spreadArray([], current), [changes]); }, []));
    return waitForLoaded(query, auditTrail$);
}
function loadedData(query) {
    // Create an observable of loaded values to retrieve the
    // known dataset. This will allow us to know what key to
    // emit the "whole" array at when listening for child events.
    return fromRef(query, ListenEvent.value).pipe(map(function (data) {
        // Store the last key in the data set
        var lastKeyToLoad;
        // Loop through loaded dataset to find the last key
        data.snapshot.forEach(function (child) {
            lastKeyToLoad = child.key;
            return false;
        });
        // return data set and the current last key loaded
        return { data: data, lastKeyToLoad: lastKeyToLoad };
    }));
}
function waitForLoaded(query, snap$) {
    var loaded$ = loadedData(query);
    return loaded$.pipe(withLatestFrom(snap$), 
    // Get the latest values from the "loaded" and "child" datasets
    // We can use both datasets to form an array of the latest values.
    map(function (_a) {
        var loaded = _a[0], changes = _a[1];
        // Store the last key in the data set
        var lastKeyToLoad = loaded.lastKeyToLoad;
        // Store all child keys loaded at this point
        var loadedKeys = changes.map(function (change) { return change.snapshot.key; });
        return { changes: changes, lastKeyToLoad: lastKeyToLoad, loadedKeys: loadedKeys };
    }), 
    // This is the magical part, only emit when the last load key
    // in the dataset has been loaded by a child event. At this point
    // we can assume the dataset is "whole".
    skipWhile(function (meta) {
        return meta.loadedKeys.indexOf(meta.lastKeyToLoad) === -1;
    }), 
    // Pluck off the meta data because the user only cares
    // to iterate through the snapshots
    map(function (meta) { return meta.changes; }));
}
